import { graphql } from "gatsby";
import React from "react";
import { EventPageQuery } from "../../graphql-types";
import { usePageContext } from "../components/context/PageContext";
import { Layout } from "../components/layout/Layout";
import { PageImageHeader } from "../components/layout/PageImageHeader";
import * as styles from "../styles/pages/event.module.scss";
import BlockContent from "@sanity/block-content-to-react";
import { GatsbyImage } from "gatsby-plugin-image";
import { StyledLink } from "../components/styled/StyledLink";
import { HomeBlocks } from "../components/blocksContent/HomeBlocks";
import { slugify } from "../utils/slugify";
import { SEO } from "../components/seo/seo";
import { BreadCrumb } from "../components/breadcrumb/BreadCrumb";

interface Props {
  data: EventPageQuery;
  location: any;
}

const EventPage: React.FC<Props> = ({ data, location }) => {
  const { lang } = usePageContext();
  const { sanityEventsPage } = data;

  return (
    <>
      <SEO rawMeta={sanityEventsPage?._rawMetadata} />
      <PageImageHeader
        image={sanityEventsPage?.data?.bgImage?.image?.asset?.gatsbyImageData}
        title={sanityEventsPage?.data?._rawTitle?.[lang]}
      />
      <BreadCrumb label={sanityEventsPage?.data?._rawTitleBreadcrumb?.[lang]} />
      <section className={styles.selections}>
        <div>
          <header>
            <h2>{sanityEventsPage?.data?._rawSelectionsTitle?.[lang]}</h2>
            <HomeBlocks
              blocks={sanityEventsPage?.data?._rawSelectionsDesc?.[lang]}
            />
          </header>
          {sanityEventsPage?.data?.selections?.map(item => (
            <article key={item?._key}>
              <div>
                <StyledLink
                  path={`/event/${slugify(item?.content?._rawTitle?.fr)}` ?? ""}
                >
                  <GatsbyImage
                    image={item?.content?.img?.image?.asset?.gatsbyImageData}
                    alt={item?.content?.img?._rawAlt?.[lang]}
                    className={styles.selectionImage}
                  />
                  <div className={styles.absolute}>
                    <div>
                      <h3 className={styles.title}>
                        {item?.content?._rawTitle?.[lang]}
                      </h3>
                      <div className={styles.button}>
                        {item?.content?._rawCta?.[lang]}
                      </div>
                    </div>
                  </div>
                </StyledLink>
              </div>
            </article>
          ))}
        </div>
      </section>
      <section className={styles.bottomContent}>
        <HomeBlocks
          blocks={sanityEventsPage?.data?._rawBottomContent?.[lang]}
          centerDesktop
          centerButton
        />
      </section>
    </>
  );
};

export const QUERY = graphql`
  query EventPage {
    sanityEventsPage {
      _rawMetadata(resolveReferences: { maxDepth: 10 })
      data {
        _rawTitleBreadcrumb
        _rawTitle
        _rawSelectionsTitle
        _rawSelectionsDesc
        bgImage {
          _rawAlt
          image {
            asset {
              gatsbyImageData
            }
          }
        }
        _rawBottomContent
        selections {
          ... on SanitySelection {
            _key
            _type
            content {
              _rawTitle
              _rawCta
              img {
                _rawAlt
                image {
                  asset {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          ... on SanitySubEvent {
            _key
            _type
            content {
              _rawTitle
              _rawCta
              img {
                _rawAlt
                image {
                  asset {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default EventPage;
